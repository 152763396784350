import Button from "@leafygreen-ui/button";
import { useChallenge } from "../../providers/Challenge";

export default function BarStart() {
  const { nextQuestion } = useChallenge();
  return (
    <Button
      variant="primary"
      onClick={nextQuestion}
      type="submit"
    >
      Start Challenge &gt;&gt;
    </Button>
  )
}