import ItemInfo from "./ItemInfo";
import ItemForm from "./ItemForm";
import ItemClaim from "./ItemClaim";
import BarStart from "./BarStart";
import BarBackNext from "./BarBackNext";

export default function ChallengeItem(props) {
  return (
    <>
      {props.type === "info" &&
        <ItemInfo {...props} />
      }
      {props.type === "form" &&
        <ItemForm {...props} />
      }
      {props.type === "claim" &&
        <ItemClaim {...props} />
      }
      <br/>
      {props.actionBar === "start" &&
        <BarStart />
      }
      {!props.actionBar &&
        <BarBackNext />
      }
    </>
  )
}