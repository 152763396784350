import { H3, Body } from "@leafygreen-ui/typography";
import Button from "@leafygreen-ui/button";
import { css } from "@leafygreen-ui/emotion";
import { QRCode } from 'react-qrcode-logo';
import { useChallenge } from "../../providers/Challenge";

const QRCODE_SIZE = 450;

const centerStyle = css`
  text-align: center;
`

export default function ItemClaim() {
  const { answers, backToStart } = useChallenge();
  return (
    <>
      <H3>Claim your MongoDB Challenge Prize</H3>
      <Body>
        Congratulations! You have completed the MongoDB Challenge. Are you sure you have all the right answers?
        Go back to the MongoDB booth with the following QR Code, and validate your answers.
      </Body>
      <div className={centerStyle}>
        <QRCode
            value={`${window.location.origin}/validate/${answers._id}`}
            size={QRCODE_SIZE}
            ecLevel={"Q"}
            quietZone={20}
            bgColor={"#FFFFFF"}
            fgColor={"#023430"}
            logoImage="/logo-square-dark-green.png"
            logoWidth={QRCODE_SIZE * 0.2}
            logoOpacity={0.8}
            logoPadding={1}
            logoPaddingStyle={"square"}
            removeQrCodeBehindLogo={true}
            qrStyle={"fluid"}
            eyeRadius={undefined}
            eyeColor={"#023430"}
          />

      </div>

      <div className={centerStyle}>
        <Button variant="primaryOutline" onClick={backToStart}>Back to challenge</Button>
      </div>
    </>
  )
}