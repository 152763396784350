import Header from "./Header";
import Card from "@leafygreen-ui/card";
import { useChallenge } from "../providers/Challenge";

export default function Layout(props) {
  const { challenge } = useChallenge();
  return(
    <>
      <Header title={challenge?.siteTitle}/>

      <Card>
        {props.children}
      </Card>
    </>
  )
}