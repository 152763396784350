import "./styles.css";
import "./fonts.css";
import LeafygreenProvider from '@leafygreen-ui/leafygreen-provider';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import Home from "./pages/Home";
import Reset from "./pages/Reset";
import Validate from "./pages/Validate";
import { ChallengeProvider } from "./providers/Challenge";


function App() {
  return (
    <ChallengeProvider>
      <LeafygreenProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/validate/:id" element={<Validate />} />
          </Routes>
        </Router>
      </LeafygreenProvider>
    </ChallengeProvider>
  );
}

export default App;