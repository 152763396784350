import { useNavigate } from "react-router-dom";
import { useChallenge } from "../providers/Challenge";

export default function Reset() {
  const navigate = useNavigate();
  const { reset } = useChallenge();
  reset();
  navigate("/");
  return null;
}
