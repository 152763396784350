import { Body } from "@leafygreen-ui/typography";

export default function ItemInfo(props) {
  return(
    <>
      {props.text.map((text, index) => {
        return(
          <Body key={index} baseFontSize={16}>{text}</Body>
        )
      })}
    </>
  )
}