import { Body, H3 } from "@leafygreen-ui/typography";
import Button from "@leafygreen-ui/button";
import TextInput from "@leafygreen-ui/text-input";
import { RadioGroup, Radio } from "@leafygreen-ui/radio-group";
import { useChallenge } from "../../providers/Challenge";

export default function ItemForm(props) {
  const {answers, setAnswers } = useChallenge();

  const handleChange = (e, item) => {
    let newAnswers = {...answers};
    newAnswers.answers[answers.step][item.id] = e.target.value;
    setAnswers(newAnswers);
  }

  return(
    <>
      <H3>{props.title}</H3>
      {props.text.map((text, index) => {
        return(
          <Body key={index} baseFontSize={16}>{text}</Body>
        )
      })}<br/>
      {props.button &&
        <>
          <Button variant="primary"
            href={props.button.link}
            target="_blank"
          >
            {props.button.text}
          </Button>
          <br key={1}/><br key={2}/>
        </>
      }
      <form>
        {props.formItems.map((item, index) => {
          return(
            <span id={index}>
              {(item.type === "text" || item.type === "email") &&
                <>
                  <TextInput
                    key={index}
                    type={item.type}
                    label={item.label}
                    placeholder={item.placeholder}
                    required={item.required}
                    value={answers.answers[answers.step][item.id]}
                    onChange={(e) => handleChange(e, item)}
                  />
                  <br/>
                </>
              }
              {item.type === "radio" &&
                <>
                  <Body>{item.label}</Body>
                  <RadioGroup
                    value={answers.answers[answers.step][item.id]}
                    onChange={(e) => handleChange(e, item)}
                  >
                    {item.options.map((option, index) => {
                      return(
                        <Radio key={index} value={option.value}>
                          {option.label}
                        </Radio>
                      )
                    })}
                  </RadioGroup><br/>
                </>
              }
            </span>
          )
        })}
      </form>
    </>
  )
}