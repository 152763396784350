import { useState, useEffect } from "react";
import { H3, Body, Disclaimer } from "@leafygreen-ui/typography";
import TextInput from "@leafygreen-ui/text-input";
import Button from "@leafygreen-ui/button";
import Icon from "@leafygreen-ui/icon";
import { useChallenge } from "../providers/Challenge";
import { useParams, Link } from "react-router-dom";
import { Table, TableHead, TableBody, Row, Cell } from "@leafygreen-ui/table";
import packageInfo from "../../package.json";

const PASSWORD="Challenge";

export default function Validate() {
  const { validate } = useChallenge();
  const { id } = useParams();

  let [authenticated, setAuthenticated] = useState(false);
  let [password, setPassword] = useState("");
  let [validation, setValidation] = useState({});

  useEffect(() => {
    const loadValidation = async () => {
      let validation = await validate(id);
      setValidation(validation);
    }
    loadValidation();
  }, [id, validate]);

  const CorrectIcon = (props) => {
    if (props.isCorrect) return <Icon style={{color: "#00ED64"}} glyph="CheckmarkWithCircle" size="xlarge" />
    return <Icon style={{color: "#FF0000"}} glyph="XWithCircle" size="xlarge" />
  }


  return (
    <>
      <H3>Validation</H3>
      {authenticated &&
        <>
          <Table shouldAlternateRowColor={true}>
            <TableHead>
              <Row>
                <Cell>Question</Cell>
                <Cell>Correct Answer</Cell>
                <Cell>User Answer</Cell>
                <Cell></Cell>
              </Row>
            </TableHead>
            <TableBody>
              {validation.map((v, index) => {
                return (
                  <Row key={index}>
                    <Cell>
                      {v.sectionTitle}<br/>
                      {v.question}
                    </Cell>
                    <Cell>
                      {v?.correctAnswer?.substr(0, 8) === "https://" &&
                        <Link to={v.correctAnswer} target="_blank">Verify</Link>
                      }
                      {v?.correctAnswer?.substr(0, 8) !== "https://" &&
                        v.correctAnswer
                      }
                    </Cell>
                    <Cell>{v.userAnswer}</Cell>
                    <Cell><CorrectIcon isCorrect={v.isCorrect} /></Cell>
                  </Row>
                )
              })}
            </TableBody>
          </Table>
        </>
      }

      {!authenticated &&

        <>
          <Body>Enter the password</Body>
          <TextInput
            label="Super Secret Password"
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variant="primary"
            onClick={() => setAuthenticated(password === PASSWORD)}
          >
            Submit
          </Button>
        </>

      }
      <Disclaimer>
        Running {packageInfo.name} {packageInfo.version}
      </Disclaimer>
    </>
  )
}
