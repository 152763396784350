import Button from "@leafygreen-ui/button";
import { useChallenge } from "../../providers/Challenge";
import { css } from "@leafygreen-ui/emotion";

const backButtonStyle = css`
  float: left;
`

const nextButtonStyle = css`
  float: right;
`

const barStyle = css`
  height: 2em;
`

export default function BarStart() {
  const { previousQuestion, nextQuestion } = useChallenge();
  return (
    <div className={barStyle}>
      <Button
        variant="default"
        onClick={previousQuestion}
        className={backButtonStyle}
        type="submit"
      >
        Back
      </Button>
      <Button
        variant="primary"
        onClick={nextQuestion}
        className={nextButtonStyle}
        type="submit"
      >
        Next
      </Button>
    </div>
  )
}