import { useState, useEffect } from "react";
import { H2 } from "@leafygreen-ui/typography";
import Stepper from "@leafygreen-ui/stepper";
import Layout from "../components/Layout";
import ChallengeItem from "../components/ChallengeItem";
import { useChallenge } from "../providers/Challenge";

export default function Home () {
  let { answers, challenge } = useChallenge();
  let [content, setContent] = useState();

  useEffect(() => {
    setContent(challenge?.sections[answers.step]);
  }, [answers.step, challenge, answers])

  return  (
    <Layout>
      <H2>Welcome to the MongoDB Challenge</H2>
      <br/>
      <ChallengeItem {...content} />

      <br/>
      <br/>
      <Stepper
        currentStep={answers.step}
        maxDisplayedSteps={5}
      >
        {challenge?.sections.map((section, index) => {
          return (
            <div key={index}>
              {section.title}
            </div>
          )
        })}
      </Stepper>
    </Layout>
  )
}