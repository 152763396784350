import { createContext, useContext, useEffect, useState } from "react";
import config from "../config";

function ChallengeProvider(props) {
  let [challenge, setChallenge] = useState();

  let [answers, setAnswers] = useState({ step: 0 });

  const loadChallengeFromDatabase = async () => {
    let challengeData = await fetch(`${config.BACKEND.URL}/challenge`).then(resp => resp.json());
    setChallenge(challengeData);
  };

  const getBlankAnswers = () => {
    let blankAnswers = { step: 0 };
    blankAnswers.answers = challenge?.sections?.map((section) => {
      let sectionQuestions = {};
      section?.formItems?.forEach((item) => {
        sectionQuestions[item.id] = "";
      });
      return sectionQuestions;
    });

    return blankAnswers;
  }


  useEffect(() => {
    const initialize = async () => {
      await loadChallengeFromDatabase();
    }
    initialize();
  }, []);

  useEffect(() => {
    let baseState = localStorage.getItem("answers");
    if (baseState) {
      setAnswers(JSON.parse(baseState));
      return;
    }
    setAnswers(getBlankAnswers());
  }, [challenge])

  const reset = () => {
    localStorage.removeItem("answers");
    setAnswers(getBlankAnswers());
  }

  const validate = async (id) => {
    return await fetch(`${config.BACKEND.URL}/validate/${id}`).then(resp => resp.json());
  }

  const nextQuestion = async () => {
    let newAnswers = ({...answers, step: answers.step + 1});
    localStorage.setItem("answers", JSON.stringify(newAnswers));
    await fetch(`${config.BACKEND.URL}/answers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(answers)
    }).then(resp => resp.json()).then(data => {
      if (!newAnswers._id) newAnswers._id = data.insertedId;
    });
    setAnswers(newAnswers);
  }

  const previousQuestion = () => {
    setAnswers({...answers, step: answers.step - 1});
  }

  const backToStart = () => {
    setAnswers({ ...answers, step: 0 });
  }

  let providerState = {
    answers,
    setAnswers,
    nextQuestion,
    previousQuestion,
    backToStart,
    reset,
    validate,
    challenge,
  }

  return (
    <ChallengeContext.Provider value={providerState}>
      {props.children}
    </ChallengeContext.Provider>
  );
}

const ChallengeContext = createContext({});
const useChallenge = () => useContext(ChallengeContext);

export {
  ChallengeContext,
  useChallenge,
  ChallengeProvider
}
